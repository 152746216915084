import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Loadable from "@loadable/component";
import "../../node_modules/flexmonster/flexmonster.css";
const LoadableFlexmonsterPivot = Loadable(() => import("../components/FlexmonsterPivot"));

const FlexPage = () => {
	return (
		<Layout>
			<SEO title="Flex" />
			<div className="container px-4">
				<h1 className="pt-12 text-2xl leading-tight font-semibold">Flex</h1>
				<LoadableFlexmonsterPivot></LoadableFlexmonsterPivot>
				<p className="my-8">
					Az adattár négy országgyűlési (2006-18) választások adatait tartalmazza, valamint a legutolsó EU parlamenti (2019) és önkormányzati választások (2019) eredményeit. Az adatokat a <a  className="link" href="http://valasztas.hu">valasztas.hu</a> oldalról töltöttük le, majd egy egységes formátumra konvertáltuk őket. A 2006 és 2010-os választások eredményeit az új választási térképre vetítettük. Kérdéseket és észrevételeket szívesen fogadunk a <a className="link" href="mailto:info@mostvasarnap.hu">info@mostvasarnap.hu</a> email címen.
				</p>

			</div>
		</Layout>
	);
};

export default FlexPage;
